import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = (props: PageProps) => (
  <Layout>
    <SEO title="London Front End Developer Contact Page" />
    <h2>Contact</h2>
    <p>
      You can view and contact me via my public profile on{" "}
      <a href="//www.linkedin.com/in/jtarmitage/">LinkedIn</a>,{" "}
      <a href="//x.com/j_armitage">X (formerly known as Twitter)</a> and{" "}
      <a href="//www.facebook.com/jtarmitage">Facebook</a>.
    </p>
    <Link to="/">Return to homepage</Link>
  </Layout>
)

export default Contact
