import React from "react"
import { Link } from "gatsby"
import "./tile.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const InternalTile = ({ link, color, iconType, title }) => (
  <Link to={link}>
    <div className="tile" style={{ backgroundColor: color }}>
      <FontAwesomeIcon className="icon" icon={iconType} size="5x" width={80} />
      <span className="description">{title}</span>
    </div>
  </Link>
)

const ExternalTile = ({ link, color, iconType, title }) => (
  <a href={link}>
    <div className="tile" style={{ backgroundColor: color }}>
      <FontAwesomeIcon className="icon" icon={iconType} size="5x" width={80} />
      <span className="description">{title}</span>
    </div>
  </a>
)

export { InternalTile, ExternalTile }
