import React from "react"
import { PageProps, Link } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const Portfolio = (props: PageProps) => (
  <Layout>
    <SEO title="London Front End Developer Portfolio" />
    <h2>Portfolio</h2>
    <div className="intro">
      <p>
        These are the websites and applications I've worked on in my previous
        roles at Holiday Extras and QVC.
      </p>
    </div>
    <div className="main">
      <Row className="website">
        <Col xs={12}>
          <h3>
            <a href="https://www.qvcuk.com/">QVC</a>
          </h3>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <div className="thumbnail">
            <StaticImage
              src="../images/portfolio/qvc-logo.png"
              alt="QVC"
              loading="lazy"
            />
          </div>
          {/*<div className="thumbnail">
            <StaticImage
              src="../images/portfolio/qurio-logo-padded.png"
              alt="Qurio"
              loading="lazy"
            />
          </div>{*/}
        </Col>
        <Col>
          <div className="description">
            <h3>2020 - 2024</h3>
            <p>
              Acted as a front end consultant for the business at{" "}
              <a href="https://www.ideo.com/">IDEO</a> workshops to help shape a
              new mobile app in order to attract new customers to the business.
              Created split tests for the exploratory phase of the app. In the
              next phase, once it had been branded as Qurio, started building
              the app in a cross-functional team with React Native, TypeScript
              and GraphQL. Developed a CI/CD pipeline to automate app deployment
              in Azure. Added bridging code in Xcode and Android Studio to
              handle timed video recording. Devised a testing strategy with the
              business analytics team and implemented the plan. Created the
              Qurio marketing website with React. Provided technical leadership
              to a small team of front end developers, within the wider
              cross-functional team, to develop live streaming, video on demand
              and AI assistant React components for the web.
            </p>
            <StaticImage
              src="../images/portfolio/qurio-promo.jpg"
              alt="Qurio promotional image"
              loading="lazy"
            />
            <h3>2015 - 2019</h3>
            <p>
              Joined a global front end team working across multiple time zones
              to migrate the QVC websites from IBM WebSphere Commerce to Adobe
              Experience Manager and redesign them with a ‘mobile first’
              mindset. Progressed to Senior level in September 2017. Developed
              front end code for all areas of the sites including homepage,
              product listings, product detail and checkout. Trained and
              mentored a junior content developer and enabled them to join the
              front end development team.
            </p>
            <h3>2013 - 2015</h3>
            <p>
              Provided a bridge between the local IT and content creation teams
              and became a technical point of contact for third parties to
              integrate marketing tags and other features. Added media queries
              to the QVC UK website, making it the first one in the global
              portfolio to be accessible using a smartphone. Migrated existing
              blogs content to a cheaper and more functional WordPress server.
            </p>

            {/*<p>
              At QVC I'm a bridge between designers, back end developers and
              business stakeholders. One of the strengths of a multinational
              company is the ability to leverage knowledge and resources between
              markets. I'm well placed with experience of working with
              international development teams at Pfizer and Holiday Extras and
              at QVC I communicate with colleagues from the US, Germany, Italy
              and Japan on a daily basis. As would be expected at a senior
              level, there are several facets to my role:
            </p>
            <ul>
              <li>
                Global projects:
                <ul>
                  <li>
                    Working within a global Agile journey team to transition the
                    QVC websites from the IBM WebSphere Commerce platform to a
                    brand-new design built on Adobe Experience Manager
                    delivering a better customer experience. This has been a
                    long term project, starting with the US market and now
                    coming to a conclusion with Japan.
                  </li>
                </ul>
              </li>
              <li>
                Local market projects:
                <ul>
                  <li>
                    Developing a new social video platform for QVC UK, where
                    customers can place reviews of QVC items and be inspired by
                    other shoppers. Qurio was written in React Native and
                    follows an entirely modern programming workflow, including
                    the use of TypeScript/GraphQL and automated CI/CD building
                    and testing with Microsoft Azure.
                  </li>
                </ul>
              </li>
            </ul>*/}
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <h3 className="spaced">Holiday Extras</h3>
        </Col>
      </Row>

      <Row className="website">
        <Col xs={12}>
          <h3>
            <a href="http://www.legolandholidays.co.uk/">Legoland Holidays</a>
          </h3>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <div className="thumbnail">
            <StaticImage
              src="../images/portfolio/legolandholidays-thumb.jpg"
              alt="Legoland Holidays"
              loading="lazy"
            />
          </div>
        </Col>
        <Col>
          <div className="description">
            <p>
              Legoland Holidays is the largest website in the short breaks
              portfolio in terms of booking volume. During my time at Holiday
              Extras I was responsible for many updates to the site. I
              implemented the homepage and extras stage in the booking process,
              and built the new{" "}
              <a href="http://www.legolandholidays.co.uk/legoland-windsor-hotels/the-legoland-hotel.html">
                Legoland Hotel
              </a>{" "}
              page. The site is based on bespoke designs generated by the user
              experience team using{" "}
              <a href="http://www.mockflow.com/">Mockflow</a> and I was expected
              to ensure elements appear exactly the same across all modern
              browsers. Sprites are used widely across the site to reduce the
              number of HTTP requests required by the client.
            </p>
          </div>
        </Col>
      </Row>

      <Row className="website">
        <Col xs={12}>
          <h3>
            <a href="http://www.alton-towers-breaks.com/">
              Alton Towers Breaks
            </a>
          </h3>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <div className="thumbnail">
            <StaticImage
              src="../images/portfolio/altontowers-thumb.jpg"
              alt="Alton Towers Breaks"
              loading="lazy"
            />
          </div>
        </Col>
        <Col>
          <div className="description">
            <p>
              When Alton Towers redesigned their own site in early 2011, I had
              to reskin both the SEO pages and the booking process to match the
              new design. During the project I adapted the PSD of the navigation
              bar provided by the park to match the short breaks site and
              introduced many other elements to give the site a new feel, which
              was approved directly by the park. This all happened while
              ensuring that the booking process remained intact and that
              customers could continue to book using the old design until the
              rebrand was ready to launch.
            </p>
          </div>
        </Col>
      </Row>

      <Row className="website">
        <Col xs={12}>
          <h3>
            <a href="http://www.chessingtonholidays.co.uk/">
              Chessington Holidays
            </a>
          </h3>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <div className="thumbnail">
            <StaticImage
              src="../images/portfolio/chessingtonholidays-thumb.jpg"
              alt="Chessington Holidays"
              loading="lazy"
            />
          </div>
        </Col>
        <Col>
          <div className="description">
            <p>
              On the Chessington Holidays site I implemented a number of
              changes, including highlighting the on site hotels with zebra
              print backgrounds to make them stand out, implementing countdown
              clocks on the homepage to count down to the expiry of an offer,
              and multiple optimisations of the booking engine.
            </p>
          </div>
        </Col>
      </Row>

      <Row className="website">
        <Col xs={12}>
          <h3>
            <a href="http://www.thorpebreaks.co.uk/">Thorpe Breaks</a>
          </h3>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <div className="thumbnail">
            <StaticImage
              src="../images/portfolio/thorpebreaks-thumb.jpg"
              alt="Thorpe Breaks"
              loading="lazy"
            />
          </div>
        </Col>
        <Col>
          <div className="description">
            <p>
              In 2010 I redesigned Thorpe Breaks based on an original design
              from the park, similar to Alton Towers Breaks. I adapted many of
              the elements provided by the park to match the requirements of the
              breaks site, with a minimum of points raised for improvement at
              the sign off stage. Following this I was involved with general
              maintenance, especially with the many offers which needed to be
              implemented during the summer season.
            </p>
          </div>
        </Col>
      </Row>

      <Row className="website">
        <Col xs={12}>
          <h3>Eden Project Breaks</h3>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <div className="thumbnail">
            <StaticImage
              src="../images/portfolio/edenbreaks-thumb.jpg"
              alt="Eden Project Breaks"
              loading="lazy"
            />
          </div>
        </Col>
        <Col>
          <div className="description">
            <p>
              Having seen the success of similar short break packages for
              Legoland and Thorpe Park, the Eden Project were keen to set up a
              new holiday site for their brand. In spring 2010 I created the
              site as part of a small team of an account manager, a project
              manager and a back-end developer.
            </p>
          </div>
        </Col>
      </Row>

      <Row className="website">
        <Col xs={12}>
          <h3>
            <a href="http://www.paultonsbreaks.com/">Paultons Breaks</a>
          </h3>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <div className="thumbnail">
            <StaticImage
              src="../images/portfolio/paultonsbreaks-thumb.jpg"
              alt="Paultons Breaks"
              loading="lazy"
            />
          </div>
        </Col>
        <Col>
          <div className="description">
            <p>
              In late 2010 I was asked to completely redesign Paultons Breaks
              after the park updated their site. For many years the short breaks
              site had been neglected and was underperforming, and this meant
              that there was very little budget allocated for creating the new
              version. I created many of the images for the new site based on an
              original PSD file and turned around the redesign in time for the
              2011 season. Combined with the launch of Peppa Pig World at the
              theme park, the site went from being one of the poorest performing
              sites in the portfolio to one of the strongest brands.
            </p>
          </div>
        </Col>
      </Row>

      <Row className="website">
        <Col xs={12}>
          <h3>Show And Stay</h3>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <div className="thumbnail">
            <StaticImage
              src="../images/portfolio/showandstay-thumb.jpg"
              alt="Show And Stay"
              loading="lazy"
            />
          </div>
        </Col>
        <Col>
          <div className="description">
            <p>
              Show and Stay was Holiday Extras' own brand theatre breaks
              offering, allowing customers to create bespoke theatre, dinner and
              hotel packages in the West End of London. I often uploaded new
              images and new offers to the site.
            </p>
          </div>
        </Col>
      </Row>

      <Row className="website">
        <Col xs={12}>
          <h3>Play And Stay</h3>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <div className="thumbnail">
            <StaticImage
              src="../images/portfolio/playandstay-thumb.jpg"
              alt="Play And Stay"
              loading="lazy"
            />
          </div>
        </Col>
        <Col>
          <div className="description">
            <p>
              Along a similar line to Show and Stay, the Play and Stay site
              offered theme park breaks under its own brand name. One of my
              first tasks upon joining Holiday Extras in mid-2009 was to make
              the booking process more consistent throughout which involved
              ensuring all hotel and attraction images were present, that all
              package options appeared in a neat grid layout and that also all
              current offers appeared correctly. From time to time I performed
              routine maintenance on the site.
            </p>
          </div>
        </Col>
      </Row>

      <Row className="website">
        <Col xs={12}>
          <h3>PassSmart</h3>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <div className="thumbnail">
            <StaticImage
              src="../images/portfolio/passsmart-thumb.jpg"
              alt="PassSmart"
              loading="lazy"
            />
          </div>
        </Col>
        <Col>
          <div className="description">
            <p>
              PassSmart offered a searchable directory of independent driving
              instructors, offering learner drivers a better way of finding one
              which suits them. I created a cross-client email template for the
              brand. I also made other smaller tweaks across the rest of the
              site, while ensuring that the responsive layout worked across all
              devices.
            </p>
          </div>
        </Col>
      </Row>

      <Row className="website">
        <Col xs={12}>
          <h3>Legoland Holidays Germany</h3>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <div className="thumbnail">
            <StaticImage
              src="../images/portfolio/legolandholidaysde-thumb.jpg"
              alt="Legoland Holidays Günzburg"
              loading="lazy"
            />
          </div>
        </Col>
        <Col>
          <div className="description">
            <p>
              Through late 2009, my first major project at Holiday Extras was to
              set up a new short breaks site for Legoland in Günzburg, Germany
              within a large team of developers and account managers. This was a
              highly challenging project as it involved many new concepts for
              the business such as dealing with Lastschrift deposit bookings and
              ensuring everything on the site could be accessed in both German
              and English.
            </p>
          </div>
        </Col>
      </Row>
    </div>
    <Link to="/">Return to homepage</Link>
  </Layout>
)

export default Portfolio
